export const getEnding = (count) => {
  const lastTwoDigits = count.toString().slice(-2);
  const lastDigit = count.toString().slice(-1);
  const endings = {
    ий: [0, 5, 6, 7, 8, 9, 11, 12, 13, 14],
    ие: [1],
    ия: [2, 3, 4],
  };
  const endingsAsDict = {};
  Object.keys(endings).forEach((key) => {
    // eslint-disable-next-line no-return-assign
    endings[key].forEach((int) => endingsAsDict[int] = key);
  });
  return endingsAsDict[lastTwoDigits] || endingsAsDict[lastDigit];
};
