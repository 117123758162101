<template>
  <p
    class="table-title cut-text my-auto red-text-on-hover"
    :class="textWrap ? 'text-wrap' : 'set-max-width'"
  >
    <span class="subtitle-1 font-weight-bold mr-1">
      {{ item.title }}
    </span>
    <span
      v-if="item.restaurants && !item.contents"
      class="date-created"
    >
      {{ restaurantsCountText(item.restaurants.length) }}
    </span>
  </p>
</template>

<script>
import { getEnding } from '@/conditions/endings';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    textWrap: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    restaurantsCountText(count) {
      return `• ${count} заведен${getEnding(count)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

.set-max-width {
  max-width: 290px;
}
</style>
